import styled, { css } from 'styled-components'
import { breakpoint } from '../ThemeProvider'
import { grid } from '../ThemeProvider/utils'

export const Container = styled.div<{
  $fluid?: boolean
  $sm?: boolean
  $md?: boolean
  $lg?: boolean
  $xl?: boolean
}>`
  width: 100%;
  margin: 0 auto;
  
  padding: 0 ${grid.gutterWidth / 2}px;
  
  // breakpoints are active when...
  // 1. fluid is false
  // 1. fluid is true and another breakpoint is marked as fluid and the current one is not
  ${p => (!p.$fluid || ((p.$md || p.$lg || p.$xl) && !p.$sm)) && breakpoint.sm(() => css`max-width: ${grid.containerWidths.sm}px;`)}
  ${p => (!p.$fluid || ((p.$sm || p.$lg || p.$xl) && !p.$md)) && breakpoint.md(() => css`max-width: ${grid.containerWidths.md}px;`)}
  ${p => (!p.$fluid || ((p.$sm || p.$md || p.$xl) && !p.$lg)) && breakpoint.lg(() => css`max-width: ${grid.containerWidths.lg}px;`)}
  ${p => (!p.$fluid || ((p.$sm || p.$md || p.$lg) && !p.$xl)) && breakpoint.xl(() => css`max-width: ${grid.containerWidths.xl}px;`)}
`

export const Row = styled.div<{
  $justify?: string
  $align?: string
  $gutterWidth?: number
}>`
  display: grid;
  gap: ${p => p.$gutterWidth ?? grid.gutterWidth}px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  
  --columns: 12;
  
  ${props => !!props.$justify && css`
    justify-items: ${props.$justify};
  `}
  
  ${props => !!props.$align && css`
    align-items: ${props.$align};
  `}
`

export const ResponsiveRow = styled.div<{
  $xs?: number
  $sm?: number
  $md?: number
  $lg?: number
  $xl?: number
}>`
  display: grid;
  gap: ${grid.gutterWidth}px;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  
  --columns: ${p => p.$xs ?? 1};
  ${p => p.$sm !== undefined ? breakpoint.sm(() => css`--columns: ${p.$sm};`) : undefined}
  ${p => p.$md !== undefined ? breakpoint.md(() => css`--columns: ${p.$md};`) : undefined}
  ${p => p.$lg !== undefined ? breakpoint.lg(() => css`--columns: ${p.$lg};`) : undefined}
  ${p => p.$xl !== undefined ? breakpoint.xl(() => css`--columns: ${p.$xl};`) : undefined}
`

export const Col = styled.div<{
  $xs?: number | "full"
  $sm?: number | "full"
  $md?: number | "full"
  $lg?: number | "full"
  $xl?: number | "full"

  $xs_push?: number
  $sm_push?: number
  $md_push?: number
  $lg_push?: number
  $xl_push?: number
}>`
  grid-column: span var(--col-size) / span var(--col-size);
  grid-column-start: var(--col-push);
  
  --col-push: ${p => p.$xs_push ?? 0};
  ${p => css`--col-size: ${p.$xs === "full" ? "var(--columns)" : p.$xs ?? 1};`}
  
  ${p => breakpoint.sm(() => css`
    ${p.$sm !== undefined && css`--col-size: ${p.$sm === "full" ? "var(--columns)" : p.$sm};`}
    ${p.$sm_push !== undefined && css`--col-push: ${p.$sm_push};`}
  `)}
  
  ${p => breakpoint.md(() => css`
    ${p.$md !== undefined && css`--col-size: ${p.$md === "full" ? "var(--columns)" : p.$md};`}
    ${p.$md_push !== undefined && css`--col-push: ${p.$md_push};`}
  `)}
  
  ${p => breakpoint.lg(() => css`
    ${p.$lg !== undefined && css`--col-size: ${p.$lg === "full" ? "var(--columns)" : p.$lg};`}
    ${p.$lg_push !== undefined && css`--col-push: ${p.$lg_push};`}
  `)}
  
  ${p => breakpoint.xl(() => css`
    ${p.$xl !== undefined && css`--col-size: ${p.$xl === "full" ? "var(--columns)" : p.$xl};`}
    ${p.$xl_push !== undefined && css`--col-push: ${p.$xl_push};`}
  `)}
`

export const Hidden = styled.div<{
  $xs?: boolean
  $sm?: boolean
  $md?: boolean
  $lg?: boolean
  $xl?: boolean
}>`
  ${p => p.$sm && breakpoint.exact_sm(() => css`display: none;`)}
  ${p => p.$md && breakpoint.exact_md(() => css`display: none;`)}
  ${p => p.$lg && breakpoint.exact_lg(() => css`display: none;`)}
  ${p => p.$xl && breakpoint.exact_xl(() => css`display: none;`)}
`

export const Visible = styled.div<{
  $xs?: boolean
  $sm?: boolean
  $md?: boolean
  $lg?: boolean
  $xl?: boolean
}>`
  display: none;
  
  ${p => p.$sm && breakpoint.exact_sm(() => css`display: initial;`)}
  ${p => p.$md && breakpoint.exact_md(() => css`display: initial;`)}
  ${p => p.$lg && breakpoint.exact_lg(() => css`display: initial;`)}
  ${p => p.$xl && breakpoint.exact_xl(() => css`display: initial;`)}
`
