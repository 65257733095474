import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { Global } from './global'
import themes from './themes'
import { ScreenSizeContextProvider } from 'ui/elements/ThemeProvider/ScreenSizeContext'

const ThemeProvider: React.FC<React.PropsWithChildren<{
  variant?: keyof typeof themes
}>> = React.memo(({ variant = 'light', children }) => {
  const theme = themes[variant]

  return (
    <StyledThemeProvider theme={theme}>
      <ScreenSizeContextProvider>
        <Global />
        {children}
      </ScreenSizeContextProvider>
    </StyledThemeProvider>
  )
})

export { AvailableColors } from './palette'
export { breakpoint } from './utils'
export { useScreenClass } from './ScreenSizeContext'

export default ThemeProvider
