import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { grid } from './utils'

const useCreateContext = () => {
  const [width, setWidth] = useState<number>()

  const screenSize = useMemo(() => {
    if (!width) {
      return 'xs'
    }

    let bp = undefined
    for (const [p, s] of breakpoints) {
      if (width < s) {
        continue
      }

      if (!bp || s > grid.breakpoints[bp]) {
        bp = p as keyof typeof grid.breakpoints
      }
    }

    return bp ?? 'xs'
  }, [width])

  useEffect(() => {
    if (typeof window === undefined) {
      return
    }

    setWidth(window.innerWidth)
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb, { passive: true })

    return () => {
      window.removeEventListener('resize', cb)
    }
  }, [])

  return { screenSize, width }
}
const ScreenSizeContext = createContext<ReturnType<typeof useCreateContext> | null>(null)

export const useScreenSizeContext = () => {
    const ctx = useContext(ScreenSizeContext)

    if (ctx === null) {
        throw new Error("No ScreenSizeContextProvider available.")
    }

    return ctx
}

const breakpoints = Object.entries(grid.breakpoints)
export const useScreenClass = () => {
  const { screenSize } = useScreenSizeContext()

  return screenSize
}

export const ScreenSizeContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const ctx = useCreateContext()

    return <ScreenSizeContext.Provider value={ctx}>
        {children}
    </ScreenSizeContext.Provider>
}
